import { classnames } from '@tailwindcss-classnames';

export const descGrid = classnames(
  'grid',
  'gap-0',
  'grid-cols-2',
  'items-end',
  'pt-2',
  'justify-end'
);

export const servicesWrapper = classnames('flex', 'flex-col', 'justify-end');

const darkCopy = classnames('text-nzxt-light-grey-700');
const defaultCopy = classnames('text-nzxt-light-grey-400');

export const smallText = (hasBackground: boolean): string =>
  classnames('text-xs', 'mb-1', 'md:hidden', 'visible', 'flex-grow', {
    [darkCopy]: hasBackground,
    [defaultCopy]: !hasBackground,
  });

export const wrapper = classnames('flex', 'flex-col', 'h-full');
export const growWrapper = classnames('flex-grow', 'mb-3', 'mt-1');

const defaultPlacementQB = classnames('top-10', 'right-10');
const smallPlacementQB = classnames(
  'md:top-10',
  'md:right-10',
  'hidden',
  'md:block'
);

export const slaContainer = classnames(
  'text-xs',
  'flex',
  'items-center',
  'text-nzxt-green-400',
  'mb-1',
  'font-semibold'
);

export const slaIcon = classnames('hidden', 'md:block', 'w-4', 'h-4', 'mr-1');

export const quickBuyPlacement = (isSmall: boolean): string =>
  classnames('absolute', 'pl-4', 'pb-4', 'md:pl-2', 'md:pb-4', {
    [defaultPlacementQB]: !isSmall,
    [smallPlacementQB]: isSmall,
  });
