import { FC, memo } from 'react';
import cn from 'clsx';
import { useRouter } from 'next/router';
import GTM from '@utils/gtm';
import { MODAL_VIEW_PRODUCT_DETAILS } from '@constants';
import useUiStore, {
  getSetModalView,
  getOpenModal,
  getSetProductDetails,
} from '@stores/use-ui-store';
import { useTranslation as t } from '@utils/hooks';
import type { Variant } from '@framework/api/types';
import slugify from '@utils/slugify';
import css from '../QuickShop.module.css';
import * as styles from './styles';

type Props = {
  selectedVariant: Variant;
  name: string;
  isSmall?: boolean;
  slug?: string;
};

const QuickShop: FC<Props> = ({
  selectedVariant,
  slug,
  name,
  isSmall = false,
}) => {
  const setModalView = useUiStore(getSetModalView);
  const openModal = useUiStore(getOpenModal);
  const setProductDetails = useUiStore(getSetProductDetails);

  const router = useRouter();
  const { asPath, locale } = router;

  const handleGTM = (eventName: string, payload): void => {
    GTM.dataLayer({
      dataLayer: {
        event: eventName,
        ...payload,
      },
    });
  };

  const handleInfoClick = (prSlug: string): void => {
    setProductDetails({ slug: prSlug.split('?')[0], isQuickShop: true });
    setModalView(MODAL_VIEW_PRODUCT_DETAILS);
    openModal();
    handleGTM('ProductDetailsModalFromProductCard', {
      triggeredFrom: { locale: locale ?? '', route: asPath ?? '' },
      productShown: name ?? '',
    });
  };

  const QUICKSHOP_LABEL = t('label_quickshop');
  const DETAILS_LABEL = t('details');

  return (
    <button
      type="button"
      onClick={() => handleInfoClick(slug)}
      className={cn(styles.overviewPlacement(isSmall), css.hoverItem)}
      aria-label={`${DETAILS_LABEL} ${name} ${selectedVariant?.color
        .map(color => color.name)
        .join('-')} `}
      id={`${slugify(name)}-quick-buy-button`}
    >
      <div className={styles.copyContainer}>
        <p className={styles.copy}>{QUICKSHOP_LABEL}</p>
      </div>
    </button>
  );
};

export default memo(QuickShop);
