import {
  DEFAULT_LANGUAGE_CODE,
  GERMANY_COUNTRY_CODE,
  FRANCE_COUNTRY_CODE,
  ITALY_COUNTRY_CODE,
  NETHERLANDS_COUNTRY_CODE,
  SPAIN_COUNTRY_CODE,
  JAPAN_COUNTRY_CODE,
} from '@constants';

export default function getStockNotification(
  language: string,
  stock: number
): string {
  switch (language?.toLowerCase()) {
    // english
    case DEFAULT_LANGUAGE_CODE?.toLowerCase():
      return `Only ${stock} left!`;
    // spanish
    case SPAIN_COUNTRY_CODE?.toLowerCase():
      return `¡Sólo quedan ${stock}!`;
    // italian
    case ITALY_COUNTRY_CODE?.toLowerCase():
      return `Ne rimangono solo ${stock}!`;
    // dutch
    case NETHERLANDS_COUNTRY_CODE?.toLowerCase():
      return `Nog maar ${stock} over!`;
    // french
    case FRANCE_COUNTRY_CODE?.toLowerCase():
      return `Il n'en reste que ${stock} !`;
    // german
    case GERMANY_COUNTRY_CODE?.toLocaleLowerCase():
      return `Nur noch ${stock} übrig!`;
    // japanese
    case JAPAN_COUNTRY_CODE?.toLowerCase():
      return `Only ${stock} left!`;
    default:
      return '';
  }
}
