/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import type { Variant } from '@framework/api/types';
import * as styles from './styles';

import ProductPrice from './ProductPrice';

type Props = {
  setSelectedVariant?: (variant: Variant) => void;
  selectedVariant: Variant;
  isRegionallyAvailable?: boolean;
  slug: string;
  currencyCode: string;
  isSmall?: boolean;
  handleClick?: () => void;
  hasBackground?: boolean;
};

const ProductCard: FC<Props> = ({
  currencyCode,
  slug,
  selectedVariant,
  isRegionallyAvailable,
  isSmall = false,
  hasBackground = false,
}) => (
  <div
    className={styles.isVisible(
      Boolean(isRegionallyAvailable && selectedVariant?.price > 0),
      isSmall
    )}
  >
    <ProductPrice
      slug={slug}
      hasBackground={hasBackground}
      priceBase={selectedVariant.price}
      priceDiscount={selectedVariant.discounted_price}
      isOnSale={selectedVariant.isOnSale}
      currencyCode={currencyCode}
      isSmall={isSmall}
    />
  </div>
);

export default memo(ProductCard);
