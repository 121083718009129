/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, useMemo, FC } from 'react';
import Link from 'next/link';
import { ROUTE_PRODUCT } from '@constants';
import { useTranslation as t } from '@utils/hooks';
import type { Variant } from '@framework/api/types';
import slugify from '@utils/slugify';
import ColorSelectInner from './ColorSelectInner';
import * as styles from './styles';

type Props = {
  variants?: Variant[];
  setVariant?: (variant: Variant) => void;
  selectedVariant: Variant;
  smallSelects?: boolean;
  isRegionallyAvailable?: boolean;
  productName?: string;
  slug?: string;
  isFaux?: boolean;
  isSmall?: boolean;
  useMarginTop?: boolean;
  handleClick?: () => void;
  customSelect?: (index: number) => void;
  hybrid?: boolean;
};
const ColorSelectButton: FC<Props> = ({
  variants,
  setVariant,
  selectedVariant,
  smallSelects = false,
  isRegionallyAvailable = true,
  productName,
  isFaux = false,
  slug,
  handleClick,
  useMarginTop = false,
  customSelect,
  hybrid = false,
}) => {
  const handleSelect = (index: number): void => {
    setVariant(variants[index]);
  };
  const CHOOSE_LABEL = t('choose_label');

  const selectedVariantIndex = useMemo(
    () => variants.indexOf(selectedVariant),
    [variants, selectedVariant]
  );

  const sizeOuter = smallSelects ? '28px' : '32px';
  const sizeInner = smallSelects ? '20px' : '26px';

  const variantsWithState = variants.map((v, i) => ({
    ...v,
    isActiveVar: i === selectedVariantIndex,
  }));

  return (
    <>
      {variantsWithState.map((variant, i) =>
        variant.color && variant.color.length > 0 ? (
          isFaux ? (
            <Link
              passHref
              href={
                hybrid
                  ? `/${ROUTE_PRODUCT}/${slug}`
                  : {
                      pathname: `/${ROUTE_PRODUCT}/${slug}`,
                      query: { variant: i },
                    }
              }
              key={`/${ROUTE_PRODUCT}/${slug}/${variant.id}`}
              role="option"
              aria-selected={Boolean(i === selectedVariantIndex)}
              aria-label={`${CHOOSE_LABEL} ${productName} ${variant.color
                .map(color => color.name)
                .join(' ')}`}
              className={styles.activeButton(
                i === selectedVariantIndex,
                !isRegionallyAvailable || variant.isOutOfStock,
                smallSelects,
                useMarginTop
              )}
              style={{
                borderRadius: '50%',
                width: sizeOuter,
                height: sizeOuter,
              }}
              onClick={handleClick ? () => handleClick() : null}
              onKeyDown={handleClick ? () => handleClick() : null}
              tabIndex={0}
            >
              <span
                className={styles.colorSelectButton}
                style={{
                  borderRadius: '50%',
                  width: sizeInner,
                  height: sizeInner,
                }}
              >
                <ColorSelectInner
                  smallSelects={smallSelects}
                  variantColor={variant.color}
                />
              </span>
            </Link>
          ) : (
            <button
              aria-pressed={Boolean(i === selectedVariantIndex)}
              aria-label={`${CHOOSE_LABEL} ${productName} ${variant.color
                .map(color => color.name)
                .join(' ')}`}
              id={`variant-select-button-${slugify(
                productName || 'empty'
              )}-${slugify(variant.color.map(color => color.name).join('-'))}`}
              onClick={
                customSelect ? () => customSelect(i) : () => handleSelect(i)
              }
              className={styles.activeButton(
                i === selectedVariantIndex,
                !isRegionallyAvailable || variant.isOutOfStock,
                smallSelects,
                useMarginTop
              )}
              key={`${variant.id}-${variant.color
                .map(color => color.name)
                .join('/')}`}
              style={{
                borderRadius: '50%',
                width: sizeOuter,
                height: sizeOuter,
              }}
              type="button"
            >
              <span
                className={styles.colorSelectButton}
                style={{
                  borderRadius: '50%',
                  width: sizeInner,
                  height: sizeInner,
                }}
              >
                <ColorSelectInner
                  smallSelects={smallSelects}
                  variantColor={variant.color}
                />
              </span>
            </button>
          )
        ) : null
      )}
    </>
  );
};

export default memo(ColorSelectButton);
