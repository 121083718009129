import { classnames } from '@tailwindcss-classnames';

const defaultPlacement = classnames('bottom-0', 'right-0', 'left-0');
const smallPlacement = classnames(
  'md:bottom-0',
  'md:right-0',
  'md:left-0',
  'hidden',
  'md:block',
  'w-full'
);

export const overviewPlacement = (isSmall: boolean): string =>
  classnames('absolute', 'w-full', {
    [defaultPlacement]: !isSmall,
    [smallPlacement]: isSmall,
  });

export const copyContainer = classnames(
  'bg-nzxt-dark-grey-500',
  'text-nzxt-dark-grey-50',
  'hover:bg-nzxt-volt-400',
  'hover:text-white',
  'focus:text-white',
  'transition-all',
  'py-2',
  'text-center'
);
export const copy = classnames('text-sm');
