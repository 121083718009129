/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, ReactNode } from 'react';
import * as styles from './styles';

type Props = {
  children?: ReactNode;
  id?: string;
};

const CardWrapper: FC<Props> = ({ children, id }) => (
  <div data-test-id={id} className={styles.cardWrapper}>
    {children}
  </div>
);

export default memo(CardWrapper);
