import { FC, memo, useState, Dispatch, SetStateAction } from 'react';
import { useRouter } from 'next/router';
import { IconShoppingCart } from '@nzxt/react-icons';
import useUiStore, { getOpenCart } from '@stores/use-ui-store';
import { useTranslation as t } from '@utils/hooks';
import GTM from '@utils/gtm';
import useAddItem from '@framework/cart/use-add-item';
import type { Variant } from '@framework/api/types';
import slugify from '@utils/slugify';
import getExtendPricePoint from '@components/Extend/get-extend-price-point';
import getExtendIsPeripheral from '@components/Extend/get-extend-is-peripheral';
import * as styles from './styles';

type Props = {
  selectedVariant: Variant;
  currencyCode: string;
  name: string;
  setIsLoading?: Dispatch<SetStateAction<boolean>>;
};

const QuickBuy: FC<Props> = ({
  selectedVariant,
  currencyCode,
  name,
  setIsLoading,
}) => {
  const openCart = useUiStore(getOpenCart);
  const { asPath, pathname } = useRouter();
  const isCartPage = pathname?.includes('cart');

  const [loading, setLoading] = useState(false);
  const quantity = 1;
  let isPeripheral;

  const addItem = useAddItem('quickshop modal');
  const addToCart = async (): Promise<void> => {
    setLoading(true);

    if (setIsLoading) {
      setIsLoading(true);
    }

    // Extend - Determines if product is a warrantable peripheral
    if (getExtendIsPeripheral(selectedVariant?.builderCategory)) {
      isPeripheral = true;
    }
    // Extend - End code

    try {
      if (selectedVariant?.shopify_variants_id) {
        // eslint-disable-next-line security/detect-non-literal-fs-filename
        window?.Extend?.modal?.open({
          referenceId: selectedVariant?.shopify_variants_id,
          onClose: async (plan, product) => {
            if (plan && product && window?.ExtendShopify) {
              const planVariant = await getExtendPricePoint(plan);

              const items = [
                {
                  shopify_variants_id: selectedVariant?.shopify_variants_id,
                  quantity,
                },
                {
                  shopify_variants_id: parseInt(planVariant, 10),
                  quantity,
                  attributes: [
                    {
                      key: `Extend.IsExtendWarranty`,
                      value: `true`,
                    },
                    plan
                      ? {
                          key: `_Extend.PlanId`,
                          value: `${plan.planId}`,
                        }
                      : null,
                    {
                      key: `_Extend.IsPricePoint`,
                      value: `true`,
                    },
                    {
                      key: `_Extend.ProductId`,
                      value: `${selectedVariant.shopify_variants_id}`,
                    },
                    isPeripheral
                      ? {
                          key: 'PeripheralId',
                          value: `${selectedVariant.shopify_variants_id}`,
                        }
                      : null,
                  ],
                },
              ].filter(Boolean);
              if (isCartPage) {
                await addItem(items).then(() => {
                  setLoading(false);

                  if (setIsLoading) {
                    setIsLoading(false);
                  }
                });
              } else {
                await addItem(items).then(() => {
                  openCart();
                  setLoading(false);
                });
              }
            } else if (isCartPage) {
              await addItem({
                shopify_variants_id: selectedVariant?.shopify_variants_id,
                quantity,
              }).then(() => {
                setLoading(false);

                if (setIsLoading) {
                  setIsLoading(false);
                }
              });
            } else {
              await addItem({
                shopify_variants_id: selectedVariant?.shopify_variants_id,
                quantity,
              }).then(() => {
                openCart();
                setLoading(false);
              });
            }
          },
        });
      }
      GTM.dataLayer({
        dataLayer: {
          event: 'warrantyModal',
          triggerSource: 'Quick cart action',
        },
      });
    } catch (err) {
      setLoading(false);
    }
    // track add to cart for GA enhanced ecommerce
    GTM.dataLayer({
      dataLayer: {
        event: 'addToCartQuickBuy',
        ecommerce: {
          currencyCode,
          add: {
            products: [
              {
                name,
                id: selectedVariant?.id,
                price: selectedVariant?.price.toString(),
                brand: 'NZXT',
                variant: Array.isArray(selectedVariant?.color)
                  ? selectedVariant?.color.map(color => color.name).join('/')
                  : null,
                quantity,
                productTitle: name,
                pageName: asPath,
              },
            ],
          },
        },
      },
    });

    // track add to cart via facebook pixel
    if (window.fbq) {
      window.fbq('track', 'AddToCartQuickBuy', {
        content_name: name,
        content_ids: [selectedVariant?.id],
        content_type: 'product',
        value: selectedVariant?.price,
        currency: currencyCode,
      });
    }
  };

  const ADD_TO_CART_LABEL = t('add_to_cart');
  return (
    <button
      onClick={addToCart}
      type="button"
      disabled={loading}
      className={styles.quickBuyButton}
      aria-label={`${ADD_TO_CART_LABEL} ${name} ${selectedVariant?.color
        .map(color => color.name)
        .join('-')} `}
      id={`${slugify(name)}-quick-buy-button`}
    >
      <IconShoppingCart className={styles.shoppingCart} />
    </button>
  );
};

export default memo(QuickBuy);
