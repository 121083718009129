import { classnames } from '@tailwindcss-classnames';

export const textBase = classnames(
  'text-xs',
  'flex',
  'items-center',
  'text-nzxt-green-400',
  'mb-1',
  'font-semibold'
);

export const icon = classnames('hidden', 'md:block', 'w-4', 'h-4', 'mr-1');
