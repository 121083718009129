import { classnames } from '@tailwindcss-classnames';

export const image = classnames('z-0', 'max-w-lg');
const defaultBg = classnames('bg-nzxt-light-grey-50', 'dark:bg-purpfiction');
const whiteBg = classnames('bg-white/50');
const darkBg = classnames('bg-prime-grad-alt', 'dark:bg-prime-grad-alt-dm');
export const imageWrapper = (hasBackground: boolean, isDark: boolean): string =>
  classnames('relative', 'w-full', 'p-4', 'xl:p-10', 'mb-1', {
    [whiteBg]: hasBackground && !isDark,
    [defaultBg]: !hasBackground && !isDark,
    [darkBg]: isDark,
  });

export const textWrapper = classnames(
  'absolute',
  'z-[5]',
  'top-10',
  'left-10',
  'h-full'
);

export const quickBuyButton = classnames(
  'bg-nzxt-volt-400',
  'border',
  'rounded-full',
  'p-2.5',
  'scale-100',
  'transform',
  'duration-200',
  'hover:scale-110'
);
export const shoppingCart = classnames(
  'text-white',
  'fill-current',
  'h-5',
  'w-5'
);
