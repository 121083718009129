import { classnames } from '@tailwindcss-classnames';

const smallHeading = classnames('text-sm', 'md:text-lg', 'md:leading-tight');
const defaultHeading = classnames('text-lg');

export const heading = (isSmall: boolean): string =>
  classnames(
    'font-bold',
    'text-nzxt-light-grey-800',
    'dark:text-white',
    'mb-1',
    {
      [smallHeading]: isSmall,
      [defaultHeading]: !isSmall,
    }
  );
export const shortDescription = classnames('flex', 'col-span-2', 'w-full');

const defaultColor = classnames(
  'text-nzxt-light-grey-600',
  'dark:text-nzxt-dark-grey-200'
);
const darkCopy = classnames('text-nzxt-light-grey-700');
export const descriptionColor = (hasBackground: boolean): string =>
  classnames('text-xs', {
    [darkCopy]: hasBackground,
    [defaultColor]: !hasBackground,
  });

export const container = classnames('mb-4');

export const hybridContainer = classnames(
  'ml-2',
  'text-[#15A5AD]',
  'font-normal',
  'text-sm'
);

export const hybridIcon = classnames('w-3', 'h-3', 'inline', 'mr-1');
