/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';

import * as styles from './styles';

type Props = {
  label?: string;
  spaceTop?: boolean;
  alertColor?: 'green' | 'red';
};

const Notification: FC<Props> = ({ alertColor, spaceTop = false, label }) =>
  label ? (
    <div className={styles.countContainer(spaceTop)}>
      <p className={styles.notificationCopy[alertColor]}>{label}</p>
    </div>
  ) : null;

export default memo(Notification);
