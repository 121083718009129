import { classnames } from '@tailwindcss-classnames';

const baseStyles = classnames('font-semibold', 'text-xs');
export const notificationCopy = {
  green: classnames('text-nzxt-green-400', baseStyles),
  red: classnames('text-nzxt-red-400', baseStyles),
};

const topMargin = classnames('mt-3');

export const countContainer = (spaceTop: boolean): string =>
  classnames('flex', 'col-span-2', 'w-full', 'mb-1', { [topMargin]: spaceTop });
