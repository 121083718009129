/* eslint-disable camelcase */
import { FC, memo } from 'react';
import usePrice from '@framework/use-price';

import * as styles from './styles';

type Props = {
  priceBase: number;
  priceDiscount?: number;
  isOnSale: boolean;
  currencyCode: string;
  slug: string;
  isSmall?: boolean;
  hasBackground?: boolean;
};

// TODO: Remove 'suppressHydrationWarning' from price items
// suppressHydrationWarning is a temporary solution for the server/client mismatch

const ProductPrice: FC<Props> = ({
  priceBase,
  priceDiscount,
  currencyCode,
  isOnSale,
  slug,
  isSmall = false,
  hasBackground = false,
}) => {
  const { price: discoPrice } = usePrice({
    amount: priceDiscount,
    currencyCode,
  });

  const { price } = usePrice({
    amount: priceBase,
    currencyCode,
  });

  return (
    <div className={styles.priceContainer}>
      <p
        className={styles.isOnSale(isOnSale, isSmall, hasBackground)}
        data-test-id={`${slug}-price`}
        suppressHydrationWarning
      >
        {price}
      </p>
      {isOnSale && (
        <p
          suppressHydrationWarning
          className={styles.discountedPricedText(isSmall)}
          data-test-id={`${slug}-on-sale-price`}
        >
          {discoPrice}
        </p>
      )}
    </div>
  );
};

export default memo(ProductPrice);
