/* eslint-disable max-len */
/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC } from 'react';
import * as styles from './styles';

type Props = {
  extendedHeading?: string;
  extendedDetails?: string[];
  isFauxPrice?: boolean;
  isSmall?: boolean;
};

const CardExtendedDetails: FC<Props> = ({
  extendedHeading,
  extendedDetails,
  isFauxPrice = false,
  isSmall = false,
}) => {
  const hasDetail =
    extendedDetails &&
    Array.isArray(extendedDetails) &&
    extendedDetails?.length > 0;

  const minHeightStyles = !isFauxPrice ? { minHeight: '81px' } : {};

  return (
    <div
      className={styles.container(isFauxPrice)}
      style={{ ...minHeightStyles, flexBasis: 'max-content' }}
    >
      {extendedHeading && (
        <p
          className={styles.heading(hasDetail, isSmall)}
          data-test-id="extended-card-heading"
        >
          {extendedHeading}
        </p>
      )}
      {hasDetail &&
        extendedDetails?.map(detail => {
          const hasIcon = detail.includes(' - $icon$');

          const detailText = hasIcon ? detail.replace(' - $icon$', '') : detail;

          return (
            <p
              key={detail}
              className={styles.subheading(isFauxPrice)}
              data-test-id="extended-card-detail"
            >
              {detailText}
              {hasIcon && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="9"
                  height="11"
                  viewBox="0 0 9 11"
                  fill="none"
                  className={styles.hybridIcon}
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M2.62532 0.415489C2.74316 0.162115 2.99773 0 3.27776 0H6.62469C6.87711 0 7.11105 0.131982 7.2411 0.34776C7.37116 0.563537 7.37829 0.831525 7.25989 1.05388L6.03955 3.34576H7.78077C8.05302 3.34576 8.30195 3.4991 8.42386 3.74191C8.54577 3.98472 8.51978 4.27539 8.3567 4.49285L3.6926 10.7123C3.50334 10.9646 3.17111 11.0642 2.87371 10.9578C2.57631 10.8513 2.3834 10.5637 2.39823 10.2489L2.55427 6.93578H0.719232C0.473707 6.93578 0.245145 6.81085 0.11302 6.60444C-0.0191042 6.39802 -0.0365345 6.13865 0.0667901 5.91649L2.62532 0.415489ZM3.73696 1.43478L1.84575 5.501H3.30808C3.50469 5.501 3.69273 5.58128 3.82845 5.72317C3.96417 5.86505 4.03575 6.05616 4.02652 6.25205L3.94513 7.98015L6.3446 4.78053H4.8432C4.59078 4.78053 4.35684 4.64855 4.22678 4.43278C4.09673 4.217 4.0896 3.94901 4.20799 3.72665L5.42833 1.43478H3.73696Z"
                    fill="#01959D"
                  />
                </svg>
              )}
            </p>
          );
        })}
    </div>
  );
};

export default memo(CardExtendedDetails);
