/* eslint-disable jsx-a11y/anchor-is-valid */
import { memo, FC, ReactNode } from 'react';
import * as styles from './styles';

type Props = {
  children?: ReactNode;
};

const CardContentWrapper: FC<Props> = ({ children }) => (
  <div className={styles.cardContentWrapper}>{children}</div>
);

export default memo(CardContentWrapper);
