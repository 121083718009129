import { classnames } from '@tailwindcss-classnames';

export const optionsGrid = classnames('space-y-2', 'flex');
export const copyGrid = classnames(
  optionsGrid,
  'items-end',
  'text-nzxt-light-grey-800'
);
export const colorSelectItem = classnames('flex', 'ml-auto', 'items-start');

const darkCopy = classnames('text-nzxt-light-grey-700');
const defaultCopy = classnames('text-nzxt-light-grey-600');
export const lightGreyCopy = (hasBackground: boolean): string =>
  classnames('ml-auto', 'flex', 'text-xs', 'my-auto', 'underline', 'mr-2', {
    [darkCopy]: hasBackground,
    [defaultCopy]: !hasBackground,
  });
