import { classnames } from '@tailwindcss-classnames';

export const cardWrapper = classnames(
  'flex',
  'flex-col',
  'h-full',
  'max-w-md',
  'relative'
);

export default cardWrapper;
