import { classnames } from '@tailwindcss-classnames';

export const priceContainer = classnames('flex', 'flex-wrap', 'w-full');

// discounted styles
const priceDiscounted = classnames('line-through', 'mr-2');
const smallText = classnames('text-xs', 'md:text-sm');
const darkCopy = classnames('text-nzxt-light-grey-700');
const defaultCopy = classnames(
  'text-nzxt-light-grey-600',
  'dark:text-nzxt-dark-grey-200'
);
export const isOnSale = (
  salePrice: boolean,
  isSmall: boolean,
  hasBackground: boolean
): string =>
  classnames({
    [priceDiscounted]: salePrice,
    [smallText]: isSmall,
    [darkCopy]: hasBackground,
    [defaultCopy]: !hasBackground && salePrice,
  });

export const discountedPricedText = (isSmall: boolean): string =>
  classnames('text-nzxt-light-grey-800', 'dark:text-white', {
    [smallText]: isSmall,
  });
const noOpacity = classnames('opacity-0');
const fullOpacity = classnames('opacity-100');

const smallSpan = classnames('col-span-2', 'md:col-span-1');
export const isVisible = (hasVisibility: boolean, isSmall: boolean): string =>
  classnames({
    [smallSpan]: isSmall,
    [noOpacity]: !hasVisibility,
    [fullOpacity]: hasVisibility,
  });
