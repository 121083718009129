import { classnames } from '@tailwindcss-classnames';

export const smallText = classnames(
  'italic',
  'text-xs',
  'text-nzxt-light-grey-300',
  'md:hidden',
  'visible'
);
const smallBehaviour = classnames('hidden', 'md:flex');

export const colorSelectButton = classnames(
  'border-2',
  'border-transparent',
  'flex',
  'flex-row',
  'items-center',
  'justify-center',
  'focus:outline-none',
  'relative'
);
export const innerColorSelect = classnames('absolute');
const inActiveBackground = classnames(
  'border',
  'border-nzxt-light-grey-600',
  'border-dashed'
);

const inActiveBackgroundAvail = classnames(
  'border-nzxt-light-grey-600',
  'border'
);
const activeBackground = classnames('border-2', 'border-nzxt-volt-300');
const marginTop = classnames('mt-1', 'md:mt-0');
export const activeButton = (
  isActive: boolean,
  isAvailable: boolean,
  isSmall: boolean,
  useMarginTop: boolean
): string =>
  classnames('flex', 'items-center', 'justify-center', 'mr-1', 'last:mr-0', {
    [inActiveBackground]: !isActive && isAvailable,
    [inActiveBackgroundAvail]: !isActive && !isAvailable,
    [activeBackground]: isActive,
    [smallBehaviour]: isSmall,
    [marginTop]: useMarginTop,
  });
