import { memo, FC } from 'react';
import extraStyles from './extraStyles.module.css';

import * as styles from './styles';

type Props = {
  smallSelects?: boolean;
  variantColor: {
    name: string;
    color: {
      hex: string;
    };
  }[];
};
const ColorSelectInner: FC<Props> = ({
  variantColor,
  smallSelects = false,
}) => {
  const radiusItems = smallSelects ? '22px' : '26px';
  const colorPlacement = smallSelects ? '-11px' : '-13px';

  const moreThanOneColorPos0 = {
    borderTopLeftRadius: radiusItems,
    borderBottomLeftRadius: radiusItems,
    left: colorPlacement,
  };
  const moreThanOneColorPos1 = {
    borderTopRightRadius: radiusItems,
    borderBottomRightRadius: radiusItems,
    right: colorPlacement,
  };

  const RADIUS_MAP = {
    0: moreThanOneColorPos0,
    1: moreThanOneColorPos1,
  };

  const oneColor = {
    borderRadius: '50%',
    left: colorPlacement,
  };

  const css = smallSelects
    ? extraStyles.swatchSmall
    : extraStyles.swatchDefault;

  return (
    <div
      className={css}
      style={{
        borderRadius: '50%',
        backgroundColor: 'rgba(255,255,255, 0)',
        zIndex: 2,
      }}
    >
      {variantColor.map((color, i) => {
        const borderStyles = variantColor.length > 1 ? RADIUS_MAP[i] : oneColor;

        return (
          <span
            key={`${color.name}-${color.color.hex}`}
            className={styles.innerColorSelect}
            style={{
              backgroundColor: `${color.color.hex}`,
              width:
                variantColor.length > 1
                  ? `calc(${radiusItems} / 2)`
                  : radiusItems,
              height: radiusItems,
              top: colorPlacement,
              ...borderStyles,
            }}
          />
        );
      })}
    </div>
  );
};

export default memo(ColorSelectInner);
